
import Vue from 'vue';
import SshKeygen from './components/SshKeygen.vue';

export default Vue.extend({
  name: 'App',

  components: {
    SshKeygen,
  },

  data: () => ({
    //
  }),
});
