
import { Component, Vue } from 'vue-property-decorator';
import {generateKeyPair} from 'web-ssh-keygen';
import {mdiKey} from "@mdi/js";
import download from 'downloadjs';


@Component
export default class SshKeygen extends Vue {
  private keySizeStr: "2048" | "4096" = "4096";
  private hashAlg: "SHA-256" | "SHA-384" | "SHA-512" = "SHA-256";
  private email: string = "" as string;
  private generatingKeys: boolean = false as boolean;
  private publicKey: string = "" as string;
  private privateKey: string = "" as string;

  private icons = {
    mdiKey,
  };

  private async generateKeys(): Promise<void> {
    this.generatingKeys = true;
    const {privateKey, publicKey} = await generateKeyPair({
      alg: "RSASSA-PKCS1-v1_5",
      size: this.keySize,
      hash: this.hashAlg,
      name: this.email,
    });
    this.generatingKeys = false;
    this.publicKey = publicKey;
    this.privateKey = privateKey;
  }

  private downloadKey() {
    download(this.privateKey, `${this.email}_key.pem`, "text/plain");
  }

  // eslint-disable-next-line getter-return
  private get keySize(): 2048 | 4096 {
    switch (this.keySizeStr) {
      case "2048":
        return 2048;
      case "4096":
        return 4096;
    }
  }
}
